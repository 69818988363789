<template>
  <div class="app-container">
    <el-card class="form-container" shadow="never">
      <notification-form type="all"></notification-form>
    </el-card>
  </div>
</template>

<script>
import NotificationForm from '../../notification/NotificationForm.vue';

export default {
  name: 'sendNotification',
  components: { NotificationForm },
};
</script>
